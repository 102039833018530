<template>
    <svg width="15" height="15" viewBox="1 1 21 21" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
</template>
<script>
export default {
    name:'hide-search-icon'
}
</script>
