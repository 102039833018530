<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
</template>
<script>
export default {
    name:'confirm-button',
}
</script>
<style scoped lang="scss">
    svg{
        stroke:var(--category-main-color);
        background: var(--category-second-color);
        border-radius: 5px;
        padding: 5px;
    }
</style>