<template>
    <svg id="Layer_1" data-name="Layer 1" viewBox="-3 -3 30 30" fill="none" stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
</template>
<script>
export default {
    name:'categories-icon'
}
</script>
<style lang="scss" scoped>
    
</style>