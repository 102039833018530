<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 30 30" fill="none" stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
</template>
<script>
export default {
    name:'edit-button',
}
</script>
<style scoped lang="scss">
    svg{
        stroke:var(--category-main-color);
        background: var(--category-second-color);
        border-radius: 5px;
        padding: 5px;
    }
</style>